<template>
  <div class="content">
    <div class="main-screen-section inside screen6"></div>

    <div class="container inside-page content-section">
      <h1>
        Купирование приступов мигрени<ReferenceLink text="1" href="#ref1" />
      </h1>
      <div class="content-text text-size-big">
        <p>
          Основные цели лечения мигрени традиционно включали в себя снятие боли,
          восстановление функций и снижение частоты головной боли. Дополнительно
          целью может быть предотвращение прогрессирования ЭМ к ХМ.
        </p>
      </div>
      <br />
      <div class="content-text text-size-middle">
        <h3>Основные цели лечения мигрени:</h3>
        <ul class="list-block">
          <li>
            <p>уменьшение частоты и тяжести приступов ГБ</p>
          </li>
          <li>
            <p>снижение количества принимаемых препаратов для купирования ГБ</p>
          </li>
          <li>
            <p>коррекция коморбидных нарушений</p>
          </li>
          <li>
            <p>
              профилактика хронизации заболевания и улучшение качества жизни
              пациентов. 
            </p>
          </li>
        </ul>
        <p class="mb40">
          Для достижения этих целей применяются модификация образа жизни,
          немедикаментозная терапия, медикаментозная терапия, направленная на
          купирование приступов ГБ и профилактическое лечение.
        </p>
        <h3>Немедикаментозные методы</h3>
        <p>
          В первую очередь подразумевают контроль над триггерами и поведенческую
          терапию. <br />
          На практике используются следующие методы:
        </p>

        <ul class="list-block">
          <li>
            <p>Выявление и исключение триггеров;</p>
          </li>
          <li>
            <p>Активности, способствующие релаксации;</p>
          </li>
          <li>
            <p>
              Гипнотерапия (терапевтическое применение гипноза; применяется для
              лечения фобий и тревоги, управления болью, устранения привычек и
              зависимостей);
            </p>
          </li>
          <li>
            <p>
              Когнитивно-поведенческая терапия (КПТ, форма психотерапии, которая
              стимулирует пациентов на выявление и получение опыта из
              неэффективных шаблонов поведения посредством осознанного выбора в
              пользу здоровых изменений);
            </p>
          </li>
          <li>
            <p>
              Обучение технике биоуправления (обучающая техника для развития
              способности контролировать вегетативную (автономную) нервную
              систему; пациент может контролировать частоту сердечных
              сокращений, артериальное давление и температуру кожи, а также
              может расслаблять определенные мышцы).
            </p>
          </li>
        </ul>

        <br />
        <h3>Медикаментозные терапии</h3>
        <p>
          Основаны на назначении лекарственных препаратов для лечения мигрени.
          Лекарственные препараты, применяемые при мигрени, можно разделить на
          неотложные и профилактические.
        </p>
        <p>
          В некоторых случаях пациентам с эпизодической мигренью достаточно
          немедикаментозных методов лечения и неотложной терапии; однако
          пациентам с хронической мигренью, как правило, требуется комплексный
          подход, подразумевающий использование профилактических и неотложных
          терапий.
        </p>
        <br />
        <p>
          Однако последние достижения в понимании патофизиологии мигрени дали
          возможность <br class="visible-xs" />
          для разработки новых фармакотерапевтических подходов, специально
          направленных на различные нейрональные механизмы .
        </p>
        <p>
          Главным новшеством стал таргетный подход — нацеленность на
          специфические нейробиологические мишени. В настоящее время лидирующей
          является стратегия, направленная на нейробиологию CGRP.
        </p>
      </div>

      <div class="content-text text-size-middle">
        <h5 class="mb30 mt-5 mb-2">Купирование приступов мигрени</h5>
        <p>
          в зависимости от тяжести приступа и степени дезадаптации пациента:
        </p>
        <ul class="list-block">
          <li>
            <p>
              лёгкий приступ мигрени – приступ, во время которого пациент в
              состоянии выполнять привычную домашнюю и профессиональную работу;
            </p>
          </li>
          <li>
            <p>
              приступ средней тяжести – выполнение этих видов деятельности
              существенно ограничено;
            </p>
          </li>
          <li>
            <p>тяжёлый приступ - необходим постельный режим.</p>
          </li>
        </ul>
        <p class="mb-4">
          Анальгетики, нестероидные противовоспалительные препараты,
          противорвотные средства, рекомендованные для купирования приступа
          мигрени легкой и средней степени тяжести<ReferenceLink
            text="1"
            href="#ref1"
          />
        </p>
        <table class="manifestations">
          <thead>
            <tr>
              <td>Фармакотерапевтическая группа</td>
              <td>МНН</td>
              <td>Способ применения</td>
              <td>Уровень доказательности</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Салициловая кислота и ее производные</td>
              <td>Ацетилсалициловая кислота</td>
              <td>
                1000 мг внутрь.<br />Продолжительность приема не более 10 дней в
                месяц
              </td>
              <td>A (1)</td>
            </tr>
            <tr>
              <td>Нестероидные противовоспалительные средства</td>
              <td>Ибупрофен</td>
              <td>
                400 мг внутрь.<br />Продолжительность приема не более 10 дней в
                месяц
              </td>
              <td>A (1)</td>
            </tr>
            <tr>
              <td>Нестероидные противовоспалительные средства</td>
              <td>Диклофенак</td>
              <td>
                50 мг внутрь.<br />Продолжительность приема не более 10 дней в
                месяц
              </td>
              <td>A (1)</td>
            </tr>
            <tr>
              <td>Нестероидные противовоспалительные средства</td>
              <td>Напроксен</td>
              <td>
                500 мг внутрь.<br />Продолжительность приема не более 10 дней в
                месяц
              </td>
              <td>A (1)</td>
            </tr>
            <tr>
              <td>Нестероидные противовоспалительные средства</td>
              <td>Кетопрофен</td>
              <td>
                75 или 150 мг.<br />Продолжительность приема не более 10 дней в
                месяц
              </td>
              <td>A (1)</td>
            </tr>
            <tr>
              <td>Прочие анальгетики и антипиретики</td>
              <td>Парацетамол</td>
              <td>
                1000 мг внутрь.<br />Продолжительность приема не более 10 дней в
                месяц
              </td>
              <td>A (1)</td>
            </tr>
          </tbody>
        </table>

        <h3>Купирование тяжелых приступов мигрени</h3>
        <p class="mb-4">
          Селективные агонисты серотониновых рецепторов типа 5НТ1 (триптаны),
          рекомендованные для купирования приступов мигрени
        </p>
        <table class="manifestations">
          <thead>
            <tr>
              <td>Фармакотерапевтическая группа</td>
              <td>МНН</td>
              <td>Способ применения</td>
              <td>Уровень доказательности</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Триптаны</td>
              <td>Суматриптан</td>
              <td>
                50 мг или 100 мг в сутки.<br />Продолжительность приема не более
                10 дней в месяц
              </td>
              <td>A (1)</td>
            </tr>
            <tr>
              <td>Триптаны</td>
              <td>Ризатриптан</td>
              <td>
                5 мг или 10 мг в сутки.<br />Продолжительность приема не более
                10 дней в месяц
              </td>
              <td>A (1)</td>
            </tr>
            <tr>
              <td>Триптаны</td>
              <td>Элетриптан</td>
              <td>
                40 мг или 80 мг в сутки.<br />Продолжительность приема не более
                10 дней в месяц
              </td>
              <td>A (1)</td>
            </tr>
            <tr>
              <td>Триптаны</td>
              <td>Золмитриптан</td>
              <td>
                2,5 мг или 5 мг в сутки.<br />Продолжительность приема не более
                10 дней в месяц
              </td>
              <td>A (1)</td>
            </tr>
          </tbody>
        </table>
      </div>
      <ReferencesList :list="referencesList" />
    </div>
  </div>
</template>

<script>
import ReferenceLink from '../components/TevaReference/ReferenceLink.vue'
import ReferencesList from '../components/TevaReference/ReferencesList.vue'

export default {
  name: 'therapy',
  components: {
    ReferenceLink,
    ReferencesList
  },
  metaInfo: {
    title: 'Лечение',
    meta: [
      {
        name: 'description',
        content:
          'Головные боли одни из распространенных расстройств нервной системы и существенно нарушают качество жизни и трудоспособность'
      }
    ]
  },
  data() {
    return {
      referencesList: [
        {
          id: 'ref1',
          text: 'КЛИНИЧЕСКИЙ ПРОТОКОЛ ДИАГНОСТИКИ И ЛЕЧЕНИЯ «МИГРЕНЬ У ВЗРОСЛЫХ». Одобрено ОККМУ-МЗ РК от «07» сентября 2023 года. Протокол №188'
        }
      ]
    }
  }
}
</script>

<style scoped></style>
